@font-face {
    font-family: 'iconway';
    src: url('../fonts/iconway.ttf') format('truetype');
}

html{

}
body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', sans-serif;
    position: relative;
    color: $paragraph;
    padding-bottom: 460px;
    padding-top: 100px;
    min-height: 100vh;
    @include media-breakpoint-down(md){
        padding-bottom: 660px;
    }
    #_wl-searchbox-holder{
        z-index: 100;
    }
}
h1{
    font-size: calc(28px + (40 - 28) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(30px + (42 - 30) * ((100vw - 300px) / (1920 - 300)));
    color: $blue;
    @media (min-width: 1920px){
        font-size: 40px !important;
    }
}
h2{
    font-size: calc(26px + (36 - 26) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(28px + (38 - 28) * ((100vw - 300px) / (1920 - 300)));
    // font-size: calc(36px + (40 - 36) * ((100vw - 300px) / (1920 - 300)));
    // line-height: calc(34px + (42 - 34) * ((100vw - 300px) / (1920 - 300)));
    color: $blue;
    @media (min-width: 1920px){
        font-size: 36px !important;
    }
}
h3{
    font-size: calc(28px + (34 - 28) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(32px + (40 - 32) * ((100vw - 300px) / (1920 - 300)));
    color: $blue;
    @media (min-width: 1920px){
        font-size: 34px !important;
    }
}
h4{
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(20px + (22 - 20) * ((100vw - 300px) / (1920 - 300)));
    @media (min-width: 1920px){
        font-size: 20px !important;
    }
}
h5{}
h6{}

p{
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(22px + (24 - 22) * ((100vw - 300px) / (1920 - 300)));
}
body:not(.page-blogindexpage){
    a{
        font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
        line-height: calc(18px + (22 - 18) * ((100vw - 300px) / (1920 - 300)));
        color: $orange;
        font-weight: 500;
        @media (min-width: 1920px){
            font-size: 18px !important;
        }
        &:hover{
            text-decoration: none;
            color: $orange;
            svg.arrow{
                transition: margin-left .2s linear;
                margin-left: 12px;
            }
        }
        svg.arrow{
            transition: margin-left .2s linear;
            margin-left: 6px;
        }
    }
}

ul{
    li{
        list-style: none;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $orange;
            left: -16px;
            top: 8px;
        }
    }
}

.container > .row{
    @include media-breakpoint-down(md){
        justify-content: center;
    }
}
.orange{
    color: $orange;
}
.uppercase{
    text-transform: uppercase;
}
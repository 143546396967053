.page-formpage{
    h1.title{
        font-size: calc(30px + (58 - 30) * ((100vw - 300px) / (1920 - 300))) !important;
        line-height: calc(38px + (64 - 38) * ((100vw - 300px) / (1920 - 300))) !important;
    }
    .side-col{
        padding-top: 40px;
        @include media-breakpoint-down(md){
            padding-top: 0;
        }
        .paragraph{
            margin-bottom: 60px;
            @include media-breakpoint-down(md){
                margin-top: 60px;
                .title{
                    font-size: 28px !important;
                }
            }
            @include media-breakpoint-down(sm){
                margin-top: 0;
            }
            .title{
                font-size: calc(28px + (36 - 28) * ((100vw - 300px) / (1920 - 300))) !important;
                line-height: calc(34px + (38 - 34) * ((100vw - 300px) / (1920 - 300))) !important;
            }
        }
    }
    .form-container{
        padding-top: 50px;
        form{
            label{
                font-size: 18px;
                color: $text-lighter;
                font-weight: 500;
            }
            input, textarea{
                padding: 10px;
                height: 51px;
                background-color: $background;
                border: 1px solid $grey-l1;
                width: 100%;
                &.submit-button{
                    width: 136px;
                    background-color: transparent;
                    height: 48px;
                    border: 1px solid $orange;
                    border-radius: 23.5px;
                    font-size: 18px;
                    font-weight: 500;
                    padding-left: 28px;
                    color: $orange;
                    text-align: unset;
                    -webkit-transition: all 0.4s;
                    -moz-transition: all 0.4s;
                    transition: all 0.4s;
                    &:focus, &.active{
                        box-shadow: none;
                        outline: none;
                    }
                    &:hover{
                        background-color: $orange;
                        color: $white;
                    }
                    &.btn-2{
                        overflow: hidden;
                        position: relative;
                    }
                    
                    &.btn-2:hover{
                        color: $white;
                    }                      
                    &.btn-2:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        -webkit-transition: all 0.4s;
                        -moz-transition: all 0.4s;
                        transition: all 0.4s;
                        width: 100%;
                        height: 0;
                        top: 0;
                        left: 0;
                        background: $white;
                    }
                    &.btn-2:hover:after,
                    &.btn-2:active:after {
                        height: 100%;
                    }
                }
            }
            .submit-container{
                width: 136px;
                margin: 0 auto;
                position: relative;
                &:hover{
                    svg{
                        filter: brightness(10);
                    }
                }
                svg{
                    width: 30px;
                    position: absolute;
                    right: 16px;
                    top: 20px;
                }
            }
            textarea{
                min-height: 250px;
            }
        }
    }
}
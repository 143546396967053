.header-image{
    width: 100vw;
    height: 98vh;
    min-height: 1000px;
    clip-path: polygon(100% 0%, 100% 93%, 50% 100%, 50% 100%, 0 93%, 0 0);
    background-size: cover;
    background-position: center;
    padding-top: 173px;
    margin-bottom: 30px;
    position: relative;
    @include media-breakpoint-down(md){
        padding: 111px 0 0 0;
        clip-path: none;
        height: auto;
        margin-bottom: 300px;
    }
    &.white-text{
        .title-col{
            h1, h2, h3, h4, h5, h6{
                color: $white !important;
                i{
                    color: $orange !important;
                    font-style: normal;
                }
            }
            p{
                color: $white !important;
                i{
                    color: $orange !important;
                    font-style: normal;
                }
            }
            ul{
                li{
                    color: $white !important;
                }
            }
        }
    }
    .title-col{
        padding-top: 40px;
        @include media-breakpoint-down(md){
            padding: 0;
            margin: 0 auto;
        }
        h2{
            font-size: calc(44px + (58 - 44) * ((100vw - 300px) / (1920 - 300))) !important;
            line-height: calc(50px + (60 - 50) * ((100vw - 300px) / (1920 - 300))) !important;
            color: $blue;
            margin-bottom: 20px;
            @media (min-width: 1920px){
                font-size: 58px !important;
                line-height: 60px !important;
            }
            i{
                color: $orange !important;
                font-style: normal;
            }
        }
        p{
            font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
            line-height: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
            color: $blue;
            font-weight: 600;
        }
        ul{
            margin-top: 10px;
            li{
                list-style: none;
                position: relative;
                font-weight: normal;
                margin-bottom: 16px;
                font-weight: 500;
                color: $blue;
                &::before{
                    content: '';
                    position: absolute;
                    width: 27px;
                    height: 1px;
                    background-color: $orange;
                    left: -40px;
                    top: 12px;
                    @include media-breakpoint-down(md){
                        width: 22px;
                        top: 10px;
                        left: -36px;
                    }
                }
                b{
                    font-weight: 600;
                }
            }
        }
    }
}
.search-col{
    padding-left: 4%;
    @include media-breakpoint-down(md){
        margin-top: 40px;
        padding-right: 30px;
        padding-left: 30px;
    }
    #_wl-searchbox-holder{
        position: relative;
        margin-bottom: 0 !important;
        &:after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 11px;
            background-color: $orange-d2;
            transform: rotate(5deg);
            top: 0;
            left: 5%;
            z-index: -1;
            @include media-breakpoint-down(md){
                transform: rotate(-3deg);
                left: 0%;
            }
        }
    }
}
#_wl-searchbox-holder{
    position: relative;
    margin-bottom: 40px;
    ._wl-searchbox{
        background-color: $blue !important;
        border-radius: 11px;
        color: $white;
        padding: 24px !important;
        h1{
            border-bottom: none;
        }
        ._wl-form-control{
            background-color: $white;
            color: $black;
            padding-left: 5px;
        }
        ._wl-toggler{
            &::before{
                color: $white !important;
                top: 0 !important;
            }
            .ng-scope{
                color: $white;
                margin-left: 20px;
            }
        }
        ._wl-btn-searcbox{
            font-size: 20px;
            font-weight: 800;
            background-color: $orange-d2;
        }
        input[type=checkbox] + i{
            background-color: $white;
            border: none;
        }
    }
}
#_wl-results-holder{
    h1._wl-search-result-heading{
        span._wl-highlight{
            color: $orange;
        }
    }
    ._wl-car-box-top-heading-main{
        color: $blue !important;
    }
    ._wl-btn{
        &:active, &:focus{
            background-color: $orange;
            outline: none;
            box-shadow: none;
        }
    }
}
#_wl-details-holder{
    h2, ._wl-rentanfo-heading, ._wl-breakdwn-heading, ._wl-rentanfo-location__heading, ._wl-rentanfo-place, ._wl-rentanfo-dots__footer{
        span, i{
            color: $blue;
            &._wl-rentanfo-location__heading-code{
                background-color: $grey-l1;
            }
        }
    }
    ._wl-button-next-to-terms{
        button{
            background-color: $blue;
        }
    }
    .contfrms-checkbox__fake-input{
        &::after{
            border-right: solid 3px $blue;
            border-bottom: solid 3px $blue;
        }
    }
}

.announcement-box{
    margin-top: 50px;
    background-color: $blue;
    border-radius: 11px;
    padding: 23px 38px;
    @include media-breakpoint-down(sm){
        border-radius: 0;
        width: 100%;
    }
    h4{
        color: $orange;
    }
    p{
        color: $white;
        font-weight: 16px;
        line-height: 24px;
    }
}
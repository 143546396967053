#search-widget {
    position: relative;
    margin-bottom: 0 !important;
    font-family: "whitelabel-custom-font", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    background-color: #1D2D5E !important;
    border-radius: 11px;
    color: $white;
    padding: 24px !important;

    h1 {
        font-weight: 400;
        font-size: 28px;
        color: $white;

        @media (min-width: 1920px) {
            font-size: 28px !important;
        }
    }

    &.showOrange::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 11px;
        background-color: #F6871F;
        transform: rotate(5deg);
        top: 0;
        left: 5%;
        z-index: -1;
    }

    label {
        font-weight: 700;

        span {
            font-weight: 400;
        }
    }

    select,
    input[type=text],
    input[type=number] {
        width: 100%;
        margin-bottom: 5px;
        border-radius: 0;
        border: none;
        background-color: $white;
        color: $black;
        padding-left: 5px;
        height: 33px;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;

        &.currency {
            margin-right: 2%;
            width: 68%;
        }
    }

    input {
        &[type=checkbox] {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:checked {
                +.fake-input {
                    &:after {
                        content: "";
                        background-color: transparent;
                        position: absolute;
                        left: 6px;
                        top: 0px;
                        width: 7px;
                        border-bottom: 2px solid #2155f4;
                        height: 14px;
                        border-right: 2px solid #2155f4;
                        transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }
                }
            }
        }

        &[type=submit] {
            background-color: #f6871f;
            border: 0;
            box-shadow: none;
            color: #fff;
            font-size: 20px;
            font-weight: 800;
            margin: 8px 0 0;
            padding: 6px 10px;
            width: 100%;
            &:disabled {
                cursor: not-allowed;
                opacity: .5;
            }
        }

        &[type=number] {
            width: 30%;
        }
    }

    .fake-input {
        background: $white;
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-right: 12px;
        position: relative;
    }


    .date-container {
        margin-right: 2%;
        width: 68%;
    }

    .time-container {
        width: 30%;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
}

.cursor-pointer{
    cursor: pointer;
}
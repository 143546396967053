.filter-wrapper{
    padding-top: 35px;
    padding-right: 15px;
    padding-bottom: 25px;
    padding-left: 15px;
    border-radius: 5px;
    background: #f0f0f0;
    color: #666;
    font-size: 14px;
    line-height: 1.4;

    .clear{
        font-size: 12px;
    }

    .header{
        margin: 0 10px 0 0;
        font-size: 14px;
        line-height: 19px;
        color: #999;
        text-transform: uppercase;
        border-bottom: 1px solid #ccc;    
    }

    input {
        &[type=checkbox] {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:checked {
                +.fake-input {
                    &:after {
                        content: "";
                        background-color: transparent;
                        position: absolute;
                        left: 6px;
                        top: 0px;
                        width: 7px;
                        border-bottom: 2px solid #2155f4;
                        height: 14px;
                        border-right: 2px solid #2155f4;
                        transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }
                }
            }
        }
    }

    label{
        cursor: pointer;
    }

    .fake-input {
        background: $white;
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-right: 12px;
        position: relative;
        box-shadow: 0 3px 6px -1px rgba(0,0,0,0.2);
        vertical-align: text-bottom;
    }

    .clear-all{
        display: block;
        width: 100%;
        padding: 8px 8px;
        margin: 0;
        border: solid 4px #ddd;
            border-top-color: rgb(221, 221, 221);
            border-right-color: rgb(221, 221, 221);
            border-bottom-color: rgb(221, 221, 221);
            border-left-color: rgb(221, 221, 221);
        font-size: 13px;
        text-transform: uppercase;
        line-height: 1.1;
        color: #999;
        background: rgba(0,0,0,0);
        border-radius: 2px;
        outline: none;
        font-weight: 700;
    }
    
}
.image-block{
    padding-top: 40px;
    padding-bottom: 60px;
    img{
        width: 100%;
        object-fit: cover;
    }
    .caption{
        background-color: $background;
        height: auto;
        min-height: 50px;
        width: 90%;
        float: right;
        p{
            color: $text-lighter;
            font-size: 15px;
            margin-bottom: 0;
            padding-right: 26px;
        }
    }
}
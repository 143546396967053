.point-list-block{
    margin-top: 130px;
    position: relative;
    margin-bottom: 100px;
    &:after{
        content: '';
        position: absolute;
        width: calc(100% + 270px);
        background-color: rgba(0, 0, 0, .15);
        bottom: -100px;
        left: -135px;
        height: 1px;
        @include media-breakpoint-down(md){
            bottom: -50px;
            width: 94%;
            left: 3%;
        }
    }
    @include media-breakpoint-down(md){
        margin-top: 40px;
        margin-bottom: 40px;
    }
    h2{
        font-size: calc(44px + (48 - 44) * ((100vw - 300px) / (1920 - 300)));
        line-height: 50px;
        margin-bottom: 50px;
        @include media-breakpoint-down(md){
            margin-bottom: 40px;
        }
    }
    .img-col{
        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        } 
        img{
            max-width: 100%;
        }
    }
    .text-col{
        margin-bottom: 28px;
        h4{
            color: $orange;
        }
        p{
            font-size: 18px;
            line-height: 24px;
        }
    }
}
.page-standardpage{
    .img-col{
        position: relative;
        margin-bottom: 40px;
        @include media-breakpoint-down(md){
            min-width: 100vw;
            min-height: 370px;
            padding: 0;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            overflow: hidden;
        }
        img{
            width: 100%;
            object-fit: cover;
            height: auto;
            max-height: 700px;
            @include media-breakpoint-down(md){
                width: auto;
                height: 100%;
                max-width: 100%;
            }
            @include media-breakpoint-down(sm){
                max-height: 500px;
            }
            @include media-breakpoint-down(xs){
                max-height: 370px;
            }
        }
        .text-container{
            position: absolute;
            top: 50%;
            transform: translateY(-80%);
            left: 0;
            width: 100%;
            color: $white;
            @include media-breakpoint-down(md){
                left: 0;
                width: 100%;
            }
            p{
                font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 500;
                line-height: 33px;
                text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
            }
            h2{
                font-size: calc(36px + (64 - 36) * ((100vw - 300px) / (1920 - 300)));
                color: $white !important;
                font-weight: 800;
                line-height: calc(38px + (64 - 38) * ((100vw - 300px) / (1920 - 300)));
                text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
            }
            h3{
                font-size: calc(20px + (36 - 20) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 500;
                line-height: calc(80px + (120 - 80) * ((100vw - 300px) / (1920 - 300)));
                text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
                color: $white;
            }
        }
    }
}
.page-blogpage{
    .title-row{
        margin-bottom: 50px;
        .subtitle{
            color: $white;
        }
    }
    .paragraph-block{
        h2{
            color: $orange;
            margin-bottom: 30px;
        }
    }
}
footer{
    position: absolute;
    bottom: 0;
    width: 100vw;
    z-index: 10;
    background-color: $background;
    height: 430px;
    @include media-breakpoint-down(md){
        height: 620px;
    }
    .footer-row{
        height: 86%;
        @include media-breakpoint-down(sm){
            height: 100%;
        }
    }
    .logo{
        svg{
            @include media-breakpoint-between(md, lg){
               margin-left: -12%; 
            }
            @include media-breakpoint-down(md){
                margin-top: 80px;
                margin-bottom: 20px;
            }
        }
    }
    ul{
        padding-left: 0;
        li{
            list-style: none;
            &::before{
                content: none !important;
            }
            a{
                font-size: 16px;
                line-height: 34px;
                font-weight: 500;
                color: $text-lighter !important;
                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
        }
    }
    .info-row{
        align-self: flex-end;
        margin-bottom: 30px;
        .info-col{
            font-size: 14px;
            color: lighten($text-lighter, 10%);
            >div{
                margin-right: 30px;
            }
            @include media-breakpoint-down(md){
                font-size: 12px;
                padding: 0 40px;
                margin: 20px 0;
                flex-wrap: wrap;
                >div{
                    margin-right: 12px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
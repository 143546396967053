header{
    position: absolute;
    top: 0;
    width: 100vw;
    left: 0;
    z-index: 200;
    height: 100px;
    &.nav-up {
        nav{
            transform: translateY(-100%);
            transition: transform .3s ease-in-out;
        }
    }
    &.nav-down {
        nav{
            transition: transform .3s ease-in-out;
            transform: translateY(0);
        }
    }
    body.menu-open &{
        background-color: $blue;
    }
    nav.navbar{
        width: 100%;
        position: fixed;
        background-color: white;
        top: 0;
        height: 100px;
        @include media-breakpoint-down(md){
            padding-left: 0;
            .navbar-brand{
                svg{
                    width: 190px;
                }
            }
            .lang-btn{
                font-size: 18px;
                color: $blue;
                text-transform: uppercase;
                font-weight: 500;
                position: absolute;
                right: 80px;
                top: 35px;
            }
            .lang-container{
                right: 65px !important;
                width: 60px !important;
                top: 65px !important;
                z-index: 10 !important;
                &::after{
                    top: 7px !important;
                }
                a{
                    height: 38px !important;
                }
            }
        }
        body.menu-open & {
            background-color: $blue;
            > .container{
                .navbar-brand{
                    svg{
                        path#text{
                            fill: $white;
                        }
                    }
                }
                .lang-btn{
                    color: $white;
                }
                .inner-container{
                    height: 100vh;
                    width: 100vw;
                    position: absolute;
                    top: 75px;
                    left: 0;
                    background-color: $blue;
                    ul{
                        padding-left: 68px;
                        padding-top: 30px;
                        li{
                            &::before{
                                content: none !important;
                            }
                            .nav-link{
                                font-size: 30px;
                                line-height: 41px;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .navbar-brand{
            @include media-breakpoint-up(md){
                margin-left: -4.4%;
            }
        }
        .inner-container{
            position: relative;
            @include media-breakpoint-down(md){
                height: 0 ;
                overflow: hidden;
            }
        }
        .navbar-toggler{
            border: none;
            @include media-breakpoint-down(md){
                position: absolute;
                right: 20px;
            }
            &:focus, &:active{
                outline: none;
                box-shadow: none;
            }
            .line{
                width: 25px;
                height: 2px;
                background-color: $blue;
                margin-bottom: 6px;
                transform: rotate(0);
                transition: transform .3s linear;
            }
            &.open{
                .line{
                    background-color: $white;
                    &.middle{
                        width: 0;
                        transition: width .3s linear;
                    }
                    &:first-child{
                        transform: rotate(45deg) translate(7px, 3px);
                        transition: transform .3s linear;
                    }
                    &:last-child{
                        transform: rotate(-45deg) translate(8px, -4px);
                        transition: transform .3s linear;
                    }
                }
            }
        }
        .navbar-nav{
            .nav-item{
                margin-right: 25px;
                height: 100px;
                display: flex;
                align-items: center;
                cursor: pointer;
                @include media-breakpoint-down(md){
                    height: 70px;
                }
                &::before{
                    content: none !important;
                }
                &.active{
                    border-bottom: 2px solid $orange;
                }
                &:hover{
                    .nav-link{
                        color: $orange !important;
                    }
                }
                &.lang-btn{
                    margin-right: -30px;
                    padding-right: 60px;
                    @include media-breakpoint-up(lg){
                        margin-left: 20px;
                        position: relative;
                        &::after{
                            content: '';
                            position: absolute;
                            left: -20px;
                            top: 40px;
                            opacity: 25%;
                            width: 1px;
                            height: 24px;
                            background-color: $blue;
                        }
                    }
                    .active-lang{
                        font-size: 16px;
                        color: $blue;
                        font-weight: 500;
                        margin: 0 -4px 0 4px;
                        text-transform: uppercase;
                    }
                }
                .nav-link{
                    font-size: 16px;
                    color: $black;
                    font-weight: 500;
                }
            }
        }
        .lang-container{
            position: absolute;
            right: -10px;
            top: 40px;
            height: 0;
            overflow: hidden;
            &.visible{
                height: auto;
                overflow: visible;
                right: 8px;
                width: 50px;
                top: 70px;
                box-shadow: 1px 1px 10px 1px rgba( 0, 0, 0, .15);
                background-color: $white;
                padding: 6px;
                border-radius: 4px;
            }
            .en-container{
                position: relative;
                cursor: pointer;
                &::after{
                    content: 'EN';
                    font-size: 16px;
                    color: $blue;
                    position: absolute;
                    font-weight: 500;
                    margin-bottom: 12px;
                    display: block;
                    top: 2px;
                }
                a{
                    z-index: 10;
                    height: 30px;
                    width: 30px;
                }
                &.fr{
                    &::after{
                        content: 'FR';
                    }
                }
                &.es{
                    &::after{
                        content: 'ES';
                    }
                }
                &.de{
                    &::after{
                        content: 'DE';
                    }
                }
            }
        }
    }
}
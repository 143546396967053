.paragraph-block{
    margin-bottom: 40px;
    h2, h3{
        margin-bottom: 20px;
    }
    img{
        margin: 20px 0;
        max-width: 100%;
        height: auto;
        &.right{
            float: right;
            margin: 20px 0 30px 20px;
            @include media-breakpoint-down(lg){
                float: none;
                margin: 20px 0;
            }
        }
        &.left{
            margin: 20px 20px 30px 0;
            float: left;
            @include media-breakpoint-down(lg){
                float: none;
                margin: 20px 0;
            }
        }
    }
    &.two-col{
        margin-bottom: 40px;
    }
}
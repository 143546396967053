.blog-index-page{
    .page-title{
        padding: 100px 0;
        border-top: 1px solid rgba(0, 0, 0, .15);
    }
    .blog-row{
        &:hover{
            img{
                transform: scale(1.02) !important;
                transition: transform .3s linear !important;
            }
        }
        @media (min-width: 1300px){
            margin-left: -9%;
            &.even{
                margin-left: unset;
                margin-right: -9%;
            }
        }
        a{
            cursor: pointer;
            text-decoration: none;
            &:hover{
                text-decoration: none;
            }
        }
        .image-container{
            overflow: hidden;
            img{
                transform: scale(1);
                transition: transform .3s linear;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .text-container{
            .title{
                border-bottom: 2px solid $orange !important;
                line-height: 60px;
            }
            p{
                color: $paragraph !important;
            }
            h4{
                font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300))) !important;
                line-height: calc(26px + (33 - 26) * ((100vw - 300px) / (1920 - 300))) !important;
            }
            .read-more{
                font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
                line-height: calc(18px + (22 - 18) * ((100vw - 300px) / (1920 - 300)));
                color: $orange;
                font-weight: 500;
                @media (min-width: 1920px){
                    font-size: 18px !important;
                }
                &:hover{
                    text-decoration: none;
                    color: $orange;
                }
            }
            a {
                text-decoration: none !important;
            }
        }
    }
    .seperator{
        background-color: $black;
        opacity: .15;
        height: 1px;
        width: 100%;
        margin-top: 70px;
        margin-bottom: 70px;

    }
}
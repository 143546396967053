.image-text-block{
    padding-top: 110px;
    padding-bottom: 110px;
    @include media-breakpoint-down(md){
        padding-top: 60px;
        padding-bottom: 60px;
        + .image-text-block{
            padding-top: 60px;
        }
        .text-col{
            margin-bottom: 50px;
            h2{
                margin-bottom: 30px;
            }
            p{
                line-height: 30px !important;
            }
        }
    }
    + .image-text-block{
        padding-top: 60px;
    }
    .right{
        img{
            margin-left: 0 !important;
        }
    }
    .img-container{
        @include media-breakpoint-down(md){
            text-align: center;
        }
        img{
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            @include media-breakpoint-down(md){
                margin: 30px auto;
            }
        }
    }
}
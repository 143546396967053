#results-widget {
    h1 {
        span {
            color: #f5861e;
        }
    }

    .car {
        position: relative;
        margin-left: 15px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 10px;
            background: transparent;
            z-index: -1;
            -webkit-box-shadow: 0 4px 10px -3px rgba(0, 0, 0, 0.2);
            box-shadow: 0 4px 10px -3px rgba(0, 0, 0, 0.2);
        } 

        .car-type {
            text-transform: uppercase;
            color: #8db8d9;
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
        }

        .car-name {
            margin: 0 0 8px;
            padding: 0;
            color: #30478d;
            // font-size: 40px;
            font-weight: 700;
            font-size: calc(26px + (36 - 26) * ((100vw - 300px) / (1920 - 300)));
            line-height: calc(26px + (36 - 26) * ((100vw - 300px) / (1920 - 300)));
            
            @media (min-width: 1920px){
                font-size: 36px !important;
            }

            span {
                color: #999999;
                font-size: 16px;
                line-height: 1;
                font-weight: normal;
            }
        }

        .car-big-info {
            margin: 0 auto;
            .car-seats {
                font-size: 14px;
                border-right: 1px solid #eee;
                &:before{
                    content: "\e905";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 10px;
                    font-size: 28px;
                    vertical-align: sub;
                }
            }
            .car-bigsuitcases {
                font-size: 14px;

                &:before{
                    content: "\e904";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 10px;
                    font-size: 28px;
                    vertical-align: sub;
                }
            }
        }

        .car-small-info {
            border-top: 1px solid #eee;
            padding-top: 8px;
            text-align: center;
            margin-right: 7px;

            .car-transmission {
                font-size: 11px;
                padding-right: 10px;
                &:before{
                    content: "\e917";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }
            .car-doors {
                font-size: 11px;
                padding-right: 10px;

                &:before{
                    content: "\e90b";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }

            .car-smallsuitcases {
                font-size: 11px;
                padding-right: 10px;

                &:before{
                    content: "\e904";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }
        }

        .supplier-logo {
            max-width: 185px;
            padding-bottom: 67px;
        }
    }

    .bt {
        border-top: 1px solid #eee;
    }

    .price {
        display: inline-block;
        vertical-align: top;
        font-size: 22px;
        line-height: 1;
        font-weight: 700;
    }

    .days {
        word-break: break-word;
        font-size: 13px;
        color: #999999;
    }


    .btn-book {
        display: inline-block;
        border: 0 none;
        padding: 14px 16px;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.2;
        text-transform: uppercase;
        border-radius: 2px;
        color: #ffffff;
        background: #30478d;
        white-space: normal;
        max-width: 150px;
    }
}
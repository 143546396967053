.results-list {

    .border-bottom-divider {
        padding-bottom: 12px;
        border-bottom: solid 1px $border-grey;
        margin-bottom: 20px;
    }

    .small-text {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
    }

    .sub-title {
        font-size: 11px;
        line-height: 18px;
        text-transform: uppercase;
        color: $text-lightest;
        letter-spacing: 1px;
    }

    .car {

        .car-type {
            text-transform: uppercase;
            color: #8db8d9;
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
        }

        .car-name {
            margin: 0 0 8px;
            padding: 0;
            color: #30478d;
            font-size: 19px;
            font-weight: 700;
            line-height: 19px;

            span {
                color: $text-lightest;
                font-size: 19px;
                line-height: 1;
                font-weight: normal;
            }
        }

        .car-big-info {
            margin: 0 auto;

            .car-seats {
                border-right: 1px solid $border-grey;
                ;
                margin-bottom: 5px;
                font-size: 13px;

                &:before {
                    content: "\e905";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 10px;
                    font-size: 28px;
                    vertical-align: sub;
                }
            }

            .car-bigsuitcases {
                font-size: 14px;

                &:before {
                    content: "\e904";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 10px;
                    font-size: 28px;
                    vertical-align: sub;
                }
            }
        }

        .car-small-info {
            border-top: 1px solid $border-grey;
            ;
            padding-top: 8px;
            text-align: center;
            margin-bottom: 35px;


            .car-transmission {
                font-size: 11px;
                padding-right: 12px;

                &:before {
                    content: "\e917";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }

            .car-airco {
                font-size: 11px;
                padding-right: 12px;

                &:before {
                    content: "";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }

            .car-doors {
                font-size: 11px;
                padding-right: 12px;

                &:before {
                    content: "\e90b";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }

            .car-smallsuitcases {
                font-size: 11px;
                padding-right: 12px;

                &:before {
                    content: "\e904";
                    font-family: 'iconway';
                    color: $icon-grey;
                    margin-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }
        }

        .supplier-logo {
            max-width: 150px;
            padding-bottom: 35px;
        }

        .title {
            color: $blue;
            font-size: 19px;
            line-height: 23px;
            font-weight: 700;
        }

        .location {
            color: $blue;
            font-size: 23px;
            font-weight: 300;
            line-height: 27px;
        }

        .rental-time-small {
            color: $text-lightest;
            text-transform: uppercase;
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
        }

        .rental-time-large {
            font-size: 17px;
            line-height: 20px;
            font-weight: 400;
        }

        .divider {
            background-color: $border-grey;
            height: 1px;
            margin: 10px 0 10px;
            margin-left: 12px;
            width: 90%;
        }

        .total {
            font-size: 17px;
            line-height: 20px;
            font-weight: 600;
        }

    }

    #results-widget {

        .sub-heading {
            font-size: 18px;
            line-height: 19px;
        }

        .sub-title {
            color: unset;
            white-space: nowrap;
        }
        &.booking-confirmation {
            .sub-title {
                color: $orange;
            }
        
            h1 {
                font-size: 28px;
                line-height: 32px;
                font-weight: 700;
            }

            h4 {
                font-weight: 400;
                color: $grey-d2;
                font-size: 18px;
                span {
                    display: flex;
                    padding: 9.5px 0;
                }
            }
            h5 {
                color: $grey-d2;
                font-weight: 400;
                font-size: 18px;
                margin-bottom: 0px;
                bottom: -10px;
                position: relative;
            }
            .booking-breakdown {
                .row {
                    display: inline;
                    margin: 10px 0;
                    font-size: 16px;
                    color: $grey-d2;
                    span {
                        color: $text-lighter;
                        margin-right: 10px;
                    }
                }
            }
            ul {
                li {
                    color: $grey-d2;
                    margin-bottom: 10px;
                    font-size: 14px;

                }
            }
        }

        .extra-container {
            padding: 8px 0;
            margin: 8px 0;

            .img-extra-container {
                width: 12%;
                max-width: 80px;

                img {
                    width: 100%;
                    height: auto;
                    align-items: start;
                }
            }

            .extra-description {
                font-size: 15px;
                line-height: 18px;
            }

            .extra-total {
                font-size: 17px;
                line-height: 20px;
            }

            .drop-down {
                border: 1px solid $border-grey;
                padding: 10px;
                border-right: 10px solid transparent;
                box-shadow: 0px 3px 5px $shadow;
            }
        }

        .form {
            .form-control {
                box-shadow: 0px 3px 5px $shadow;
            }
        }

        .price-disclaimer {
            font-size: 12px;
            line-height: 16px;
            color: $text-lightest;
        }

        .fake-input {
            font-size: 14px;
            line-height: 16px;
        }

        .book-btn {
            button {
                float: right;
            }
        }

        .payable {
            .payable-lable {
                font-size: 14px;
                line-height: 16px;
            }

            .payable-lable-divider {
                border-right: 1px solid $border-grey;
            }

            .payable-price {
                float: right;
                padding: 0 5px 5px 0;
            }

            .border-bottom-divider {
                padding-bottom: 0px;
            }

            .border-top-divider {
                border-top: solid 1px $border-grey;
            }
        }
    }
}
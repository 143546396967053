.icon-block{
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%,0);
    @include media-breakpoint-down(md){
        bottom: -260px;
    }
    .icon-col{
        .img-wrapper{
            width:32px; 
            height:32px;
            min-width: 32px;
            img{
                width: 100%;
                height: auto;
            }
        }
        @include media-breakpoint-down(md){
            margin-top: 30px;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .img-wrapper{
                margin-bottom: 12px;
            }
            img{
                width: 100%;
                height: auto;
                max-width: 37px;
            }
        }
    }
    .title{
        color: $white;
        margin-left: 18px;
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        @include media-breakpoint-down(md){
            color: $paragraph;
            font-size: 20px;
            line-height: 22px;
            margin-left: 0;
            margin-top: 10px;
        }
        &.blue{
            color: $blue !important;
        }
    }
}
.newest-blog-block{
    margin-bottom: 100px;
    @include media-breakpoint-down(md){
        padding-bottom: 40px;
    }
    .img-col{
        flex: 0 0 124% !important;
        max-width: 124% !important;
        position: relative;
        @include media-breakpoint-down(md){
            min-width: 100vw;
            min-height: 370px;
            padding: 0;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            overflow: hidden;
        }
        img{
            width: 100%;
            object-fit: cover;
            height: auto;
            max-height: 700px;
            @include media-breakpoint-down(md){
                width: auto;
                height: 100%;
                max-width: 100%;
            }
            @include media-breakpoint-down(sm){
                max-height: 500px;
            }
            @include media-breakpoint-down(xs){
                max-height: 370px;
            }
        }
        .text-container{
            position: absolute;
            top: calc(50% - 110px);
            left: 50%;
            transform: translate(-50%, 0);
            color: $white;
            @include media-breakpoint-down(md){
                width: 100%;
            }
            p{
                font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 500;
                line-height: 33px;
                text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
            }
            h2{
                font-size: calc(36px + (64 - 36) * ((100vw - 300px) / (1920 - 300)));
                color: $white !important;
                font-weight: 800;
                line-height: calc(38px + (64 - 38) * ((100vw - 300px) / (1920 - 300)));
                text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
                position: relative;
                text-decoration: none;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    background-color: $orange-d1;
                    bottom: -20px;
                    left: 0;
                    @include media-breakpoint-down(md){
                        height: 2px;
                        left: 6%;
                        width: 88%;                    
                    }
                }
                &:hover{
                    text-decoration: none;
                }
            }
            h3{
                font-size: calc(20px + (36 - 20) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 500;
                line-height: calc(80px + (120 - 80) * ((100vw - 300px) / (1920 - 300)));
                text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
                color: $white;
            }
            a {
                text-decoration: none;
            }
        }
    }
    .intro-col{
        color: $text-lighter;
        margin-top: 40px;
        text-align: center;
        @include media-breakpoint-down(md){
            a{
                margin: 0 auto;
            }
        }
    }
}
#overlay-modal{
    .modal-dialog{
        max-width: 800px;
        .modal-body{
            padding: 0;
            button{
                position: absolute;
                top: 10px;
                right: 14px;
            }
        }
    }
}

.modal-backdrop.show{
    opacity: .8;
}